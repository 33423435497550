<template>
  <div class="container">
    <!-- <nav>
      
    </nav> -->
    <div class="navbar-box">
      <el-menu class="navbar" :style="{ backgroundColor: navBackgroundColor }">
        <!-- <el-menu-item index="1"><router-link to="/home">Home</router-link></el-menu-item>
        <el-menu-item index="2"><router-link to="/about">About</router-link></el-menu-item> -->
        <div class="nav">
          <div class="menu-box">
            <div class="logo">
              <router-link :to="{ name: 'home' }" style="border-bottom: none;"><img src="@/assets/logo/logo.png"
                  style="width: 76px;height: 33px;"></router-link>
            </div>
            <!-- <el-menu-item index="1" style="font-size: 16px;">首页</router-link></el-menu-item> -->
            <el-menu-item index="2" style="font-size: 16px;"><router-link
                :to="{ name: 'databaseSystem' }">业财四流系统</router-link></el-menu-item>
            <el-menu-item index="3" style="font-size: 16px;"><router-link
                :to="{ name: 'wechatApp' }">实时业务管理</router-link></el-menu-item>
          </div>

          <div class="login">
            <el-button type="success" class="loginButton" @click="handleClickLogin">
              <img src="@/assets/icon/iconLogin.png" style="width: 20px;height: 20px;" alt="">
              <span style="font-size: 16px;">登录</span></el-button>
          </div>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, getCurrentInstance } from 'vue'
onMounted(() => {
  window.addEventListener('scroll', handleScroll);
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const navBackgroundColor = ref('transparent');
const { proxy } = getCurrentInstance();
const state = reactive({

})
//滚动监听改变导航背景色
const handleScroll = () => {
  const navHeight = window.scrollY;
  if (navHeight > 200) {
    navBackgroundColor.value = '#FFFFFF';
  } else {
    navBackgroundColor.value = 'transparent';
  }
}
//登录
const handleClickLogin = () => {
  window.open("https://data.lvlianhengsheng.com")
}

</script>

<style lang="scss" scoped>
.container {
  position: relative;

  .router-link-active {
    text-decoration: none;
    color: #00503A;
    border-bottom: 2px solid #EBEBEB;
  }

  a {
    text-decoration: none;
    color: #333333;
  }

  .navbar-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    .nav {
      margin: 0 auto;
      padding: 23px 150px;
      box-sizing: border-box;
      max-width: 1920px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        display: flex;
        align-items: center;
        margin-right: 20px;

        .content {
          width: 200px;
          display: flex;
          justify-content: space-around;
          font-size: 16px;
          color: #222222;
          font-family: "PingFang SC-Regular";
        }
      }

      .menu-box {
        display: flex;
      }

      .login {
        .loginButton {
          background-color: #00503A;
          border: none;
          width: 100px;
          height: 42px;
          border-radius: 24px;
        }
      }
    }


    .el-menu--horizontal.el-menu {
      border: none;
    }
  }

  .el-menu-item:hover {
    background-color: inherit;
  }
}
</style>