import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redired: "/home",
    name: "home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/databaseSystem",
    name: "databaseSystem",
    component: () => import("../views/databaseSystem/index.vue"),
  },
  {
    path: "/wechatApp",
    name: "wechatApp",
    component: () => import("../views/wechatApp/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则返回顶部
      return { top: 0 };
    }
  }

});

export default router;
